import { DateTime } from 'luxon'
import defaultMenuItems from 'navs/default-menu-items'
import { roleDetails } from 'store/constant'

export function encodeParams(options = {}) {
  const esc = encodeURIComponent
  const keys = Object.keys(options)
  const query = []

  keys.map((key) => {
    const value = options[key]

    if (value && Array.isArray(value)) {
      const arrayKey = `${esc(key)}[]`

      value.forEach((valueItem) => {
        query.push(`${arrayKey}=${esc(valueItem)}`)
      })
    } else if (value) {
      const arrayKey = `${esc(key)}`

      query.push(`${arrayKey}=${esc(value)}`)
    } else {
      return null
    }
  })

  return query.join('&')
}

export function previewOrientation(height, width) {
  let orientation = 'portrait'

  if (width >= height) {
    orientation = 'landscape'
  }

  return orientation
}

export function previewScale(orientation, holderSize, width, height) {
  let scale = 1

  if (orientation === 'landscape') {
    scale = (holderSize.width - holderSize.padding * 2) / width
  } else {
    scale = (holderSize.height - holderSize.padding * 2) / height
  }

  return scale
}

export function previewMargins(orientation, holderSize, imageSize) {
  const margins = {
    top: orientation === 'landscape' ? (holderSize.height - imageSize.height) / 2 : holderSize.padding,
    left: orientation === 'portrait' ? (holderSize.width - imageSize.width) / 2 : holderSize.padding,
  }

  return margins
}

export function objectsToArray(objects) {
  let array = []

  if (objects) {
    array = Object.keys(objects).map((key) => objects[key])
  }

  return array
}

export function sortArrayBy(array, direction = 'asc', sortKey = 'title') {
  const isStringSortKey = typeof sortKey === 'string'
  const getSortColumn = (item) => (isStringSortKey ? item[sortKey] : sortKey(item))

  const sorted = array.sort((a, b) => {
    const compareA = getSortColumn(a)
    const compareB = getSortColumn(b)

    if (compareA > compareB) return direction === 'asc' ? 1 : -1
    if (compareA < compareB) return direction === 'asc' ? -1 : 1

    return 0
  })

  return sorted
}

export function sortEntitiesBy(entities, direction, sortKey) {
  const array = Object.values(entities)
  return sortArrayBy(array, direction, sortKey)
}

export function getCookies() {
  const cookies = {}

  const parts = document.cookie.split('; ')
  parts.forEach((part) => {
    const [name, value] = part.split('=')
    cookies[name] = decodeURIComponent(value)
  })

  return cookies
}

export function convertBytesTo(fileSize, outputFormat = 'MB') {
  if (outputFormat === 'MB') {
    const size = fileSize / 1024 ** 2

    return `${Math.round(size * 100) / 100} ${outputFormat}`
  }

  return null
}

export function dateToFilter(filterDate, filterType) {
  let today = new Date()
  let baseDate = new Date(filterDate)
  let daysOffset = Math.floor(
    (Date.UTC(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate()) -
      Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) /
      (1000 * 60 * 60 * 24)
  )

  if (filterType === 'end') daysOffset++

  return daysOffset.toString() + 'd'
}

export function filterToDate(filterDate, filterType, userTimeZone) {
  const today = DateTime.now().setZone(userTimeZone)
  let baseDate = today

  const dateFilters = filterDate.split(' ')

  dateFilters.forEach((dateFilter) => {
    const offset = parseInt(dateFilter.slice(0, -1))
    const type = dateFilter.slice(dateFilter.length - 1)

    if (type === 'd') {
      baseDate = baseDate.plus({ days: filterType === 'start' ? offset : offset - 1 })
    }

    if (type === 'w') {
      baseDate = baseDate.plus({ weeks: filterType === 'start' ? offset : offset - 1 })

      if (filterType === 'start') {
        baseDate = baseDate.startOf('week')
      } else {
        baseDate = baseDate.endOf('week')
      }
    }

    if (type === 'm') {
      baseDate = baseDate.plus({ months: filterType === 'start' ? offset : offset - 1 })

      if (filterType === 'start') {
        baseDate = baseDate.startOf('month')
      } else {
        baseDate = baseDate.endOf('month')
      }
    }
  })

  return baseDate
}

export function covertNumberToTwoDigit(value) {
  const valueLength = value.toString().length

  if (valueLength === 0) return '00'

  return valueLength === 1 ? `0${value}` : value.toString()
}

export function convertNumberToPrice(value) {
  if (value) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(value)
  }

  return null
}

export function stringToKey(value) {
  if (value) {
    return value.toLowerCase().replace(/\s/g, '_')
  }

  return ''
}

export function digObject(target, keyString, defaultValue) {
  const keys = keyString.split('.')

  const value = keys.reduce(
    (acc, key) => {
      const accValue = typeof acc === 'undefined' || acc === null ? acc : acc[key]
      return accValue
    },
    { ...target }
  )

  if (value === null || value === undefined) return defaultValue

  return value
}

export function deepSetObject(source, keyString, value) {
  const updated = source ? JSON.parse(JSON.stringify(source)) : {}

  const keys = keyString.split('.')
  const lastKey = keys.pop()

  const lastObject = keys.reduce((acc, key) => {
    acc[key] = acc[key] || {}
    return acc[key]
  }, updated)

  lastObject[lastKey] = value

  return updated
}

function findArrayIndex(array, value, options = {}) {
  const { deepCompare, useObjectKey } = options

  // Replace items with the same key
  if (useObjectKey) {
    return array.findIndex((item) => item[useObjectKey] === value[useObjectKey])
  }

  // Deep Compare - compare objects by stringifying them
  if (deepCompare) {
    return array.findIndex((item) => JSON.stringify(item) === JSON.stringify(value))
  }

  // Simple Array
  return array.findIndex((item) => item === value)
}

export function toggleArray(array, value, options = {}) {
  const { useObjectKey } = options

  const updatedArray = array ? JSON.parse(JSON.stringify([...array])) : []

  const index = findArrayIndex(updatedArray, value, options)

  if (index !== -1) {
    // useObjectKey replaces the original object if match found
    if (useObjectKey) {
      updatedArray.splice(index, 1, value)
      return updatedArray
    }

    updatedArray.splice(index, 1)
  }

  if (index === -1) {
    updatedArray.push(value)
  }

  return updatedArray
}

export function groupBy(array, groupKey, customItem) {
  const isStringGroupKey = typeof groupKey === 'string'
  const grouped = []

  array.forEach((item) => {
    const key = isStringGroupKey ? item[groupKey] : groupKey(item)
    const collection = grouped.find((i) => i.groupKey === key)
    const formattedItem = customItem ? customItem(item) : item

    if (!collection) {
      grouped.push({
        groupKey: key,
        items: [formattedItem],
      })
    } else {
      collection.items.push(formattedItem)
    }
  })

  return grouped
}

export function generateRedirectUrl() {
  if (!window) return null

  const {
    location: { hash, pathname, search },
  } = window

  return encodeURIComponent(pathname + search + hash)
}

export function matchFilterString(string, filterString) {
  if (!string) return false

  if (filterString) {
    return string.toLowerCase().includes(filterString.toLowerCase())
  }
  return true
}

export function matchFilterNumber(number, filterNumber) {
  if (filterNumber) return Number(number) === Number(filterNumber)
  return true
}

export function matchFilterArrayIncludes(array, value) {
  if (!array) return false

  if (value) return array.includes(value)
  return true
}

export function scrollIntoViewWithOffset(element, offset) {
  const top = element.getBoundingClientRect().top + window.pageYOffset + (offset || -85)

  return window.scrollTo({
    behavior: 'smooth',
    top: top,
  })
}

export function getAvailableProviders(providers, bookableSlots, currentTask) {
  const currentBooking = currentTask?.requisites?.find((x) => x.name === 'Booking')
  const taskStart = DateTime.fromISO(currentTask?.value?.start, { zone: 'utc' }).toISO({ suppressMilliseconds: true })
  const taskEnd = DateTime.fromISO(currentTask?.value?.end, { zone: 'utc' }).toISO({ suppressMilliseconds: true })

  const availableSlots = bookableSlots.filter((x) => {
    const start = DateTime.fromISO(x.start, { zone: 'utc' }).toISO({ suppressMilliseconds: true })
    const end = DateTime.fromISO(x.end, { zone: 'utc' }).toISO({ suppressMilliseconds: true })

    return start <= taskStart && end >= taskEnd && currentBooking?.value?.service_types?.every((service) => x.services?.includes(service))
  })

  const providersAvailable = Object.values(providers).filter((provider) =>
    [...new Set(availableSlots.map((x) => x.user))].includes(provider.id)
  )

  return providersAvailable
}

export function getMenuItems(currentUser) {
  const userRole = currentUser.roles?.find((role) => roleDetails.some((item) => item.roles.includes(role)))
  return userRole ? roleDetails.find((item) => item.roles.includes(userRole)).menuItems : defaultMenuItems
}

export function getOnHoldProviders(holdSlots, checkDate) {
  const { start, end } = checkDate
  const providers = holdSlots
    ?.filter((x) => {
      const taskStart = DateTime.fromISO(x.start, { zone: 'utc' }).toISO({ suppressMilliseconds: true })
      const taskEnd = DateTime.fromISO(x.end, { zone: 'utc' }).toISO({ suppressMilliseconds: true })

      return (taskStart <= start && start < taskEnd) || (end <= taskEnd && taskStart < end) || (start <= taskStart && end >= taskEnd)
    })
    .map((x) => x.user)

  return providers
}

export function getStatusColor(status) {
  switch (status) {
    case 'NotSet':
      return { background: '#FAFAFA', text: '#BDBDBD' }
    case 'NotAvailable':
      return { background: '#FADBD8', text: '#EC7063' }
    case 'LockedInDay':
    case 'LockedInDusk':
    case 'LockedInDayDusk':
    case 'AllDay':
    case 'Dusk':
    case 'AllDayDusk':
      return { background: '#DCEDC8', text: '#33691E' }
    case 'Custom':
      return { background: '#FFF9C4', text: '#827717' }
    case 'Weekend':
      return { background: '#ECEFF1', text: '#90A4AE' }
    case 'BlockedOut':
      return { background: '#FADBD8', text: '#EC7063' }
    case 'Booked':
      return { background: '#E0F2F1', text: '#26A69A' }
    default:
      return { background: '#FAFAFA', text: '#BDBDBD' }
  }
}
