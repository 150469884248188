import { normalize } from 'normalizr'
import { Schemas } from 'redux/schema'
import { updateEntities } from 'redux/entities'

import apiV2 from 'utils/apiV2'

import { handleError } from '../../utils'

const FETCH_REQUEST = 'contenthouse/sqlServiceJobCount/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/sqlServiceJobCount/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/sqlServiceJobCount/FETCH_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  result: {},
  updating: false,
}

// Actions
export function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}) {
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadSqlServiceJobsCount(options) {
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = apiV2('/sql/service-jobs/count', options)
      .then(({ data }) => {
        const normalizedJson2 = normalize(data.serviceJobStatuses, Schemas.SQL_STATUS_ARRAY)
        dispatch(updateEntities(normalizedJson2))
        dispatch(fetchSuccess())

        const normalizedJson = normalize(data.serviceGroups, Schemas.SQL_SERVICE_GROUP_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload, errors } = action

  switch (type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        result: payload.continuationToken,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    default:
      return state
  }
}
