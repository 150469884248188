import * as entities from '../entities'

// cosmos
import * as currentUser from './cosmos/currentUser'
import * as enums from './cosmos/enum'
import * as holdSlots from './cosmos/holdSlot'
import * as merchandises from './cosmos/merchandise'
import * as officeProjects from './cosmos/officeProject'
import * as offices from './cosmos/office'
import * as orderItems from './cosmos/orderItem'
import * as parts from './cosmos/part'
import * as prices from './cosmos/price'
import * as productParts from './cosmos/productPart'
import * as products from './cosmos/product'
import * as projectTemplates from './cosmos/projectTemplate'
import * as projects from './cosmos/project'
import * as schedules from './cosmos/schedule'
import * as slots from './cosmos/slot'
import * as swap from './cosmos/swap'
import * as taskSheets from './cosmos/taskSheet'
import * as tasks from './cosmos/task'
import * as users from './cosmos/user'
import * as workflows from './cosmos/workflow'
import * as zoneProvidersEstimates from './cosmos/zoneProvidersEstimate'
import * as zoneSlots from './cosmos/zoneSlot'
import * as zones from './cosmos/zone'

// sql
import * as sqlClients from './sql/sqlClient'
import * as sqlServiceJobs from './sql/sqlServiceJob'
import * as sqlServiceJobsCount from './sql/sqlServiceJobCount'
import * as sqlStatuses from './sql/sqlStatus'
import * as sqlUsers from './sql/sqlUser'
import * as sqlZones from './sql/sqlZone'

const modules = {
  entities,
  // cosmos
  currentUser,
  enums,
  holdSlots,
  merchandises,
  officeProjects,
  offices,
  orderItems,
  parts,
  prices,
  productParts,
  products,
  projectTemplates,
  projects,
  schedules,
  slots,
  swap,
  taskSheets,
  tasks,
  users,
  workflows,
  zoneProvidersEstimates,
  zoneSlots,
  zones,
  // sql
  sqlClients,
  sqlServiceJobs,
  sqlServiceJobsCount,
  sqlStatuses,
  sqlUsers,
  sqlZones,
}

export const reducers = Object.keys(modules).reduce((acc, moduleKey) => {
  const module = modules[moduleKey]
  acc[moduleKey] = module.default

  return acc
}, {})

export default modules
